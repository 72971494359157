import { ApolloError, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { FirebaseError } from '@firebase/util'
import { useFlag, useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react'
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import rg4js from 'raygun4js'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useAnalytics } from 'use-analytics'
import config, { environment } from '../../../config'
import {
  BucketLoginSuccessDocument,
  BucketType,
  BucketUserConvertDocument,
  BucketUserMigrateDocument,
  BucketsByUserIdDocument,
  BucketsByUserIdQuery,
  MarketingPreferenceInput,
  ProfileMigrationStatus,
  SubscriptionStatus,
  TokenType,
  UserLoginDocument,
  UserLoginMutation,
  UserProfileCreateDocument,
  UserProfileDocument,
  UserSubscriptionStatus
} from '../../../graphql/generated'
import { adminUserIdVar } from '../../../providers/apollo/cache'
import logger from '../../../utils/logger'
import useBucketRedirect from '../../buckets/hooks/useBucketRedirect'
import useCheckoutParams from '../../subscription/hooks/useCheckoutParams'
import useGAEvent from './useGAEvent'

const REDIRECT_LOCAL_STORAGE_VAR = 'redirect_idp'

export default function useLoginFirebase(registeringNewUser: boolean = false) {
  const { user, identify } = useAnalytics()
  const isAdmin = useReactiveVar(adminUserIdVar)
  const { redirectToBucket } = useBucketRedirect()
  const [legacyLoginMutation] = useMutation(UserLoginDocument)
  const [userMigrateMutation] = useMutation(BucketUserMigrateDocument)
  const [userConvertMutation] = useMutation(BucketUserConvertDocument)
  const [createUserProfile, createProfileResults] = useMutation(UserProfileCreateDocument)
  const [getBucketsQuery] = useLazyQuery(BucketsByUserIdDocument, { nextFetchPolicy: 'network-only', fetchPolicy: 'network-only' })
  const [userProfileQuery] = useLazyQuery(UserProfileDocument, { nextFetchPolicy: 'cache-and-network' })
  const [bucketLoginSuccess] = useMutation(BucketLoginSuccessDocument)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const clientId = params.get('client_id')
  const { planName, billingCycle } = useCheckoutParams()
  const updateContext = useUnleashContext()
  const updateContextRef = useRef(updateContext)
  const customToken = params.get('custom_token')
  const returnUrl = window.location.href.split('returnUrl=')?.[1] ?? localStorage.getItem('__pb_returnUrl')
  const invitationId = params.get('invitationId') ?? localStorage.getItem('_pb_invitationId') ?? undefined
  const { trackEvent } = useGAEvent()
  const useNewMigrationMutation = useFlag('useNewMigrationMutation')
  const onDemandConversionEnabled = useFlag('onDemandConversionEnabled')
  const isNewCheckoutVisible = useFlag('isNewCheckoutVisible')
  const unleashClient = useUnleashClient()

  // Check for promo or coupon query params
  const promo = params.get('promo')
  const coupon = params.get('coupon')

  const couponQueryParam = () => {
    if (promo || coupon) {
      if (planName && billingCycle) {
        return `&coupon=${promo ?? coupon}`
      }
      return `?coupon=${promo ?? coupon}`
    }
    return ''
  }

  const couponQuery = couponQueryParam()

  const freeTrialUrl =
    environment === 'stage' ? `https://billing.stage.photobucket.com/?id=sharing&type=trial` : `https://my.photobucket.com/trial-signup`

  const subscriptionUrl =
    planName && billingCycle
      ? `/subscription/payment?plan_name=${planName}&billing_cycle=${billingCycle}${couponQuery}`
      : `/subscription/select-plan${couponQuery}`

  useEffect(() => {
    if (returnUrl) {
      localStorage.setItem('__pb_returnUrl', returnUrl)
    }
  }, [returnUrl])

  function attemptCustomTokenLogin() {
    if (getAuth().currentUser || !customToken) {
      return
    }
    signInWithCustomToken(getAuth(), customToken).catch(logger.error)
  }

  const attemptRedirect = async () => {
    const { uid: userId } = getAuth().currentUser ?? {}

    if (!userId) {
      throw new FirebaseError('auth/unauthenticated', 'Failed to fetch buckets. Unauthenticated.')
    }

    const onError = (err: ApolloError) => {
      logger.error({ err })
    }

    const onCompleted = async (results?: BucketsByUserIdQuery): Promise<boolean> => {
      try {
        rg4js('send', {
          event: 'attemptRedirect#subscriptionPlanDetails',
          currentBucketId: results?.userProfile?.currentBucketId,
          planName: results?.userProfile?.plan?.plan_name,
          subscriptionExpirationDate: results?.userProfile?.subscription?.expirationDate,
          subscriptionStatus: results?.userProfile?.subscription?.subscription?.status,
          userId: results?.userProfile?.id
        })
        trackEvent('plan_attributes', {
          id: results?.userProfile?.id,
          maxImageCount: results?.userProfile?.plan?.media_limit || Infinity,
          maxMediaSpace: results?.userProfile?.plan?.storage_limit || Infinity,
          planName: results?.userProfile?.subscription?.planName
        })

        try {
          // ensures user fetches correct values for unleash context
          await updateContext({ userId, properties: { planId: results?.userProfile?.plan?.plan_name ?? 'no-plan' } })

          await bucketLoginSuccess({
            variables: {
              isAdmin: !!isAdmin
            }
          })
        } catch (err) {
          logger.error(err)
        }

        let bucketConversionStarted = false
        let kafkaBucketId: string | undefined

        if (results?.userProfile?.migrationMetadata?.migrationStatus === ProfileMigrationStatus.Required) {
          try {
            if (useNewMigrationMutation) {
              await userMigrateMutation()
            } else {
              const token = await getAuth().currentUser?.getIdToken()
              if (!token) {
                logger.error('Unable to fetch idToken for migration')
              } else {
                await legacyLoginMutation({
                  variables: {
                    token
                  }
                })
              }
            }

            logger.info(`${useNewMigrationMutation ? '[NEW]' : '[LEGACY]'} Dormant user migration started, userId: ${results?.userProfile?.id}`)
          } catch (ex) {
            logger.error(
              `${useNewMigrationMutation ? '[NEW]' : '[LEGACY]'} Dormant user migration failed to start, userId: ${results?.userProfile?.id}`,
              ex
            )
          }
        } else if (results?.userProfile?.migrationMetadata?.hasKafkaBucket && onDemandConversionEnabled) {
          kafkaBucketId = results?.bucketsByUserId?.items?.find(
            (bucket) => bucket.bucketType === BucketType.Mybucket && bucket.isConverted === false
          )?.id

          if (kafkaBucketId) {
            try {
              await userConvertMutation({
                variables: {
                  bucketId: kafkaBucketId
                }
              })

              bucketConversionStarted = true

              logger.info(`Bucket conversion started for bucket ${kafkaBucketId}, userId: ${results?.userProfile?.id}`)
            } catch (err) {
              logger.error(`Bucket conversion failed to start for bucket ${kafkaBucketId}, userId: ${results?.userProfile?.id}`, err)
            }
          }
        }

        if (!results?.bucketsByUserId) {
          throw new FirebaseError('auth/failed-to-fetch', 'Failed to fetch buckets for this user.')
        }
        localStorage.removeItem(REDIRECT_LOCAL_STORAGE_VAR)

        const planId = results.userProfile?.plan?.plan_name ?? ''
        const subscriptionStatus = results.userProfile?.subscription?.subscription?.status
        const querystring = new URLSearchParams({ planId, login: 'true' }).toString()

        const transferId = params.get('transferId') ?? localStorage.getItem('_pb_transferId')
        const confirmationId = params.get('confirmationId')

        // Confirm user's email
        if (confirmationId) {
          rg4js('trackEvent', {
            type: 'redirect',
            path: `/confirm/${confirmationId}`
          })
          window.location.href = `/confirm/${confirmationId}`
          return true
        }

        // redirect user to verify DTP
        if (clientId) {
          const cookies = new Cookies()
          cookies.set('facebook_dtp', true, { ...config.cookieOptions, maxAge: 60 * 60 * 24 })
          rg4js('trackEvent', {
            type: 'redirect',
            path: `/auth/verify${location.search}`
          })
          window.location.href = `/auth/verify${location.search}`
          return true
        }

        // redirect user to invitation
        if (invitationId) {
          localStorage.removeItem('_pb_bucketId')
          localStorage.removeItem('_pb_invitationId')
          rg4js('trackEvent', {
            type: 'redirect',
            path: `/invite/${invitationId}`
          })
          window.location.href = `/invite/${invitationId}`
          return true
        }

        if (transferId) {
          localStorage.removeItem('_pb_bucketId')
          localStorage.removeItem('_pb_transferId')
          rg4js('trackEvent', {
            type: 'redirect',
            path: `/transfer/${transferId}`
          })
          window.location.href = `/transfer/${transferId}`
          return true
        }

        if (returnUrl) {
          rg4js('trackEvent', {
            type: 'redirect',
            path: returnUrl
          })
          localStorage.removeItem('__pb_returnUrl')
          logger.info({ returnUrl })
          window.location.href = returnUrl
          return true
        }

        if (registeringNewUser) {
          rg4js('trackEvent', {
            type: 'redirect',
            path: isNewCheckoutVisible ? subscriptionUrl : freeTrialUrl
          })
          window.location.href = isNewCheckoutVisible ? subscriptionUrl : freeTrialUrl
          return true
        }

        if (isAdmin) {
          rg4js('trackEvent', {
            type: 'redirect',
            path: `/u/${userId}?${querystring}`
          })
          window.location.href = `/u/${userId}?${querystring}`
          return true
        }

        let redirectBucketId = bucketConversionStarted && kafkaBucketId ? kafkaBucketId : results.userProfile?.currentBucketId

        if (redirectBucketId === getAuth().currentUser?.uid && subscriptionStatus === SubscriptionStatus.Active) {
          // falls back to legacy login flow
          redirectToBucket(redirectBucketId)
          return false
        }

        const hasAccessToCurrentBucket = Boolean(
          results.bucketsByUserId?.items.find((bucket) => bucket?.id === redirectBucketId && bucket?.subscriptionStatus === SubscriptionStatus.Active)
        )

        // Fixes user being redirected to 404
        // User was removed from their last used bucket
        // OR user has not fetched all buckets and current bucket is not in current pagination
        if (!hasAccessToCurrentBucket) {
          // TODO: Maybe mutation to remove current bucket id?
          // Consider the comments above
          rg4js('send', {
            event: 'attemptRedirect#userRemovedFromLastUsedBucket'
          })
          logger.info(`User was removed from their last used bucket. id: ${redirectBucketId}`)
          redirectBucketId = undefined
        }

        if (!redirectBucketId || !hasAccessToCurrentBucket) {
          const firstPersonalBucket = results.bucketsByUserId?.items?.find(
            (bucket) => bucket?.bucketType === BucketType.Mybucket && bucket?.subscriptionStatus === SubscriptionStatus.Active
          )
          const firstGroupBucket = results.bucketsByUserId?.items?.find(
            (bucket) => bucket?.bucketType === BucketType.Group && bucket?.subscriptionStatus === SubscriptionStatus.Active
          )
          redirectBucketId = firstPersonalBucket?.id ?? firstGroupBucket?.id
        }

        if (redirectBucketId) {
          redirectToBucket(redirectBucketId, bucketConversionStarted)
          return true
        }

        const enableNewPredeactivatedPage = unleashClient.isEnabled('enableNewPredeactivatedPage')
        const enableNewPendingPage = unleashClient.isEnabled('enableNewPendingPage')
        const enableNewDeactivatedPage = unleashClient.isEnabled('enableNewDeactivatedPage')
        const enableNewExpiredPage = unleashClient.isEnabled('enableNewExpiredPage')

        if (subscriptionStatus === SubscriptionStatus.Active) {
          window.location.href = '/bucket/create'
        } else if (subscriptionStatus === SubscriptionStatus.Expired) {
          if (enableNewExpiredPage) {
            window.location.href = '/subscription/expired'
          } else {
            window.location.href = 'https://my.photobucket.com/expired'
          }
          return true
        } else if (subscriptionStatus === SubscriptionStatus.Predeactivated) {
          if (enableNewPredeactivatedPage) {
            window.location.href = '/subscription/predeactivated'
          } else {
            window.location.href = 'https://my.photobucket.com/predeactivation'
          }
          return true
        } else if (subscriptionStatus === SubscriptionStatus.Pending) {
          logger.info({ enableNewPendingPage })
          if (enableNewPendingPage) {
            window.location.href = '/subscription/pending'
          } else {
            window.location.href = 'https://my.photobucket.com/pending'
          }
          return true
        } else if (subscriptionStatus === SubscriptionStatus.Deactivated) {
          if (enableNewDeactivatedPage) {
            window.location.href = '/subscription/deactivated'
          } else {
            window.location.href = 'https://my.photobucket.com/deactivation'
          }
          return true
        } else {
          window.location.href = '/buckets'
        }

        return true
      } catch (err) {
        logger.error({ err })
        return false
      }
    }

    // If an invitationId is present, create a user profile.
    // If the user already has a profile, this will do nothing.
    if (invitationId) {
      await createUserProfile({
        onCompleted: ({ userProfileCreate }) => {
          logger.debug({ userProfileCreate })
        },
        onError: (err) => {
          logger.error({ err })
        },
        variables: { data: {}, isConverted: true, invitationId }
      })
    }

    // Fetch the user's buckets and profile. If the user has no profile a profile and personal bucket will be created for them.
    // Note the invitationId condition above will create a profile so that a personal bucket is not created with this query
    const variables = { userId }
    const res = await getBucketsQuery({ onError, variables })
    const success = await onCompleted(res.data)
    return success
  }

  async function bucketRegister({
    optIntoMarketingEmails,
    termsAccepted
  }: {
    optIntoMarketingEmails?: MarketingPreferenceInput
    termsAccepted?: boolean
  }) {
    if (createProfileResults.loading) {
      return
    }

    await createUserProfile({
      onCompleted: attemptRedirect,
      onError: (err) => {
        logger.info({ err })
      },
      variables: {
        data: {
          ...(optIntoMarketingEmails && { optIntoMarketingEmails }),
          ...(termsAccepted && { termsAccepted })
        },
        isConverted: true,
        invitationId
      }
    })
  }

  async function handleRedirect(userLogin: UserLoginMutation['userLogin']) {
    if (clientId) {
      const cookies = new Cookies()
      cookies.set('facebook_dtp', true, { ...config.cookieOptions, maxAge: 60 * 60 * 24 })
      window.location.href = `/auth/verify${location.search}`
      return
    }

    const { subscription } = userLogin
    const planId = subscription?.plan?.planId
    const usedMediaSpace = subscription?.totalUserUsedSpace || 0
    const maxMediaSpace = subscription?.plan?.maxSpace ?? Infinity
    const usedMediaCount = subscription?.totalImagesCount || 0
    const maxMediaCount = subscription?.plan?.maxImagesCount ?? Infinity
    const overspace = usedMediaSpace >= maxMediaSpace
    const overcount = usedMediaCount >= maxMediaCount
    const overstorage = overspace || overcount
    let querystring = new URLSearchParams({ planId, login: 'true' }).toString()

    if (overstorage) {
      const urlParams = {
        login: 'true',
        overcount: overcount.toString(),
        overspace: overspace.toString(),
        overstorage: overstorage.toString(),
        planId
      }
      querystring = new URLSearchParams(urlParams).toString()
    }

    let redirectUrl = ''

    const transferId = params.get('transferId') ?? localStorage.getItem('_pb_transferId')

    const userProfile = await userProfileQuery()
    if (userProfile.data?.userProfile?.currentBucketId !== getAuth().currentUser?.uid) {
      await attemptRedirect()
    }

    const enableNewPredeactivatedPage = unleashClient.isEnabled('enableNewPredeactivatedPage')
    const enableNewPendingPage = unleashClient.isEnabled('enableNewPendingPage')
    const enableNewDeactivatedPage = unleashClient.isEnabled('enableNewDeactivatedPage')
    const enableNewExpiredPage = unleashClient.isEnabled('enableNewExpiredPage')

    // store username for local storage use
    localStorage.setItem('_pb_userData:username', userLogin.username)
    if (returnUrl) {
      redirectUrl = returnUrl
    } else if (transferId) {
      localStorage.removeItem('_pb_transferId')
      window.location.href = `/transfer/${transferId}`
      return
    } else if (invitationId) {
      localStorage.removeItem('_pb_invitationId')
      window.location.href = `/invite/${invitationId}`
      return
    } else if (isAdmin) {
      redirectUrl = `/u/${userLogin.username}?${querystring}`
    } else if (subscription.subscriptionStatus === UserSubscriptionStatus.Expired) {
      if (enableNewExpiredPage) {
        redirectUrl = '/subscription/expired'
      } else {
        redirectUrl = 'https://my.photobucket.com/expired'
      }
    } else if (subscription.subscriptionStatus === UserSubscriptionStatus.Predeactivated) {
      if (enableNewPredeactivatedPage) {
        redirectUrl = '/subscription/predeactivated'
      } else {
        redirectUrl = 'https://my.photobucket.com/predeactivation'
      }
    } else if (subscription.subscriptionStatus === UserSubscriptionStatus.Pending) {
      if (enableNewPendingPage) {
        redirectUrl = '/subscription/pending'
      } else {
        redirectUrl = 'https://my.photobucket.com/pending'
      }
    } else if (subscription.subscriptionStatus === UserSubscriptionStatus.Deactivated) {
      if (enableNewDeactivatedPage) {
        redirectUrl = '/subscription/deactivated'
      } else {
        redirectUrl = 'https://my.photobucket.com/deactivation'
      }
    } else if (registeringNewUser) {
      if (isNewCheckoutVisible) {
        redirectUrl = subscriptionUrl
      } else {
        redirectUrl = freeTrialUrl
      }
    } else if (planId.split(/[\s-_]+/)[0].toLowerCase() === 'beginner') {
      redirectUrl = `/u/${userLogin.username}?${querystring}`
    } else if (planId.split(/[\s-_]+/)[0].toLowerCase() === 'intermediate') {
      redirectUrl = `/u/${userLogin.username}?${querystring}`
    } else {
      redirectUrl = `/u/${userLogin.username}?${querystring}`
    }

    window.location.href = redirectUrl
  }

  const [mutation, results] = useMutation(UserLoginDocument, {
    onError: logger.error,
    onCompleted: ({ userLogin }) => {
      // for SSO logins where a new account is created, a unique flow occurs:
      // - the account is deleted and recreated with a new userId that is similar to the
      // beginning of the user's email address
      // - deleting the account will expire the user's idToken, which means we need
      // to sign them out on the front-end sdk and sign them back in

      // see:
      // - https://firebase.google.com/docs/auth/admin/create-custom-tokens
      // - https://firebase.google.com/docs/auth/admin/manage-sessions
      const { tokenType, token } = userLogin
      if (tokenType === TokenType.CustomAuthToken) {
        signOut(getAuth())
          .then(() => signInWithCustomToken(getAuth(), token))
          .catch((error) => {
            logger.error(error)
          })
        return
      }

      localStorage.setItem('redirect_idp', '')
      handleRedirect(userLogin)
    }
  })

  // Handle mutation results
  useEffect(() => {
    if (!results.data?.userLogin) {
      return
    }

    const { userLogin } = results.data

    async function updateUnleashContext(userId: string) {
      await updateContextRef.current({ userId, properties: { planId: userLogin.subscription.plan.planId } })
    }

    const cookies = new Cookies()
    cookies.set('app_auth', userLogin.token, config.cookieOptions)

    // GA Analytics track user
    user(userLogin.username)
    identify(userLogin.username, { planId: userLogin.subscription?.plan?.planId, ...config.app })

    // update unleash context
    updateUnleashContext(userLogin.username)
  }, [clientId, location.search, results.data, user, identify])

  return { attemptCustomTokenLogin, bucketRegister, attemptRedirect, mutation, results }
}
