import AlbumDelete from './iconButtons/AlbumDelete'
import AlbumDetails from './iconButtons/AlbumDetails'
import AlbumDownload from './iconButtons/AlbumDownload'
import AlbumMove from './iconButtons/AlbumMove'
import MediaDelete from './iconButtons/MediaDelete'
import MediaDetails from './iconButtons/MediaDetails'
import MediaDownload from './iconButtons/MediaDownload'
import MediaMove from './iconButtons/MediaMove'
import MediaPrintShop from './iconButtons/MediaPrintShop'
import PlaySlideshow from './iconButtons/PlaySlideshow'
import EditImage from './iconButtons/EditImage'
import MediaShare from './iconButtons/MediaShare'
import MediaFavorite from './iconButtons/MediaFavorite'
import useSelectedMedia from '../../hooks/useSelectedMedia'

function AlbumIconMenu() {
  return (
    <>
      <AlbumDetails />
      <AlbumDownload />
      <AlbumDelete />
      <AlbumMove />
      <PlaySlideshow />
    </>
  )
}

function MediaIconMenu() {
  return (
    <>
      <MediaDetails />
      <MediaShare />
      <MediaDownload />
      <MediaFavorite />
      <MediaDelete />
      <MediaMove />
      <EditImage />
      <MediaPrintShop />
    </>
  )
}

export default function GalleryIconButtons() {
  const { selectedMedia } = useSelectedMedia()
  if (selectedMedia.size === 0) {
    return <AlbumIconMenu />
  }
  return <MediaIconMenu />
}
